<template>
  <section class="home">
    <section class="first">
      <h1 class="title">Open Trafic <br> <span>par la Fabrique des Mobilités </span></h1>
      <br>
      <p>Le projet vise à exploiter le potentiel des logiciels de reconnaissance d’objets pour
 identifier tous les types de véhicules dans des flux vidéos, en utilisant les infrastuctures vidéos déjà existantes</p>
      <p> Le développement du POC a été piloté et réalisé par la <a href="https://lafabriquedesmobilites.fr/">Fabrique des Mobilités</a> qui mène
 des projets similaires pour développer une <a href="https://oultim.frama.site/">application de
 traçage open source</a>, pour développer un logiciel de prédiction de trafic
 <a href="https://wiki.lafabriquedesmobilites.fr/wiki/IA_Prediction_de_flux_routier">open source</a>.</p>
      <p>L’expérimentation a été réalisée avec une collectivité partenaire de la Fabrique des Mobilités, le conseil Départemental des Alpes-Maritimes (06)</p>
      <p>Le dévellopement de la partie technique a été réalisé par The Walking Nerds.</p>
      <br>
      <p>Le projet a bénéficié du volet « Mise à niveau numérique de l’État et des territoires » de France Relance, via le <a href="https://france-relance.transformation.gouv.fr/c471-utiliser-des-technologies-emergentes-pour-ame">guichet ITN6 (Utiliser des technologies émergentes pour améliorer le service public)</a>.</p>
      <div class="img-container logos">
        <img src="@/assets/LogoFranceRelance.jpg" alt="">
        <img src="@/assets/logoUEPR.jpg" alt="">
      </div>
      <router-link to="/datas" class="link center high">Découvrir les datas !</router-link>
    </section>

    <section class="second">
      <div class="img-container">
        <img src="@/assets/marina_sud_odc.png" alt="">
      </div>
      <p>Pour la DGITM, ce projet permettra d’améliorer le pilotage des politiques publiques. 
La production de nouvellesdonnées de trafic dans tout le territoire permettra à l’administration candidate de mieux 
suivre les évolutions desusages, de mieux justifier des décisions d’investissement ou encore de pouvoir quantifier des évolutions 
liées à descirconstances exceptionnelles (grèves, COVID…).</p>
      <p>Le projet vise également à améliorer l’efficacité de l’action publique.
En effet, ce projet vise à produire un kit open source, un guide de mise en œuvre et une communauté d’acteurs utilisantces ressources.
 En s’appuyant sur des logiciels open source, les acteurs publiques ne seront pas dépendants d’acteursprivés et pourront, entre eux,
 mutualiser des données, se comparer et mener des actions conjointes. Une API seraproposée pour permettre à d’autres acteurs de les
 utiliser. Des utilisations entre administrations seront possibles pourcomparer les évolutions d’usages selon plusieurs politiques
 publiques ou aménagements locaux.</p>
      <br>
      <p>Le projet <a href="https://github.com/fabmob/open_trafic" target="_blank" >sur Github</a></p>
    </section>
  </section>
</template>

<script>

export default {
  
}
</script>

<style lang="scss">
.home {
  width: 800px;
  text-align: inherit;
  .first {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-bottom: 2em;
    .logos {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: center;
      flex-direction: row;
      margin-bottom: 2em;

      img {
        max-height: 5em;
        max-width: 15em;
        width: 100%;
        object-fit: contain;
        margin: 1em;
      }
    }
    .link {
      margin-top: 1em;
    }
  }

  .second {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    .img-container {
      width: 100%;
      height: 250px;
      text-align: center;
      margin-bottom: 2em;
      img {
        width: 90%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>